<template>
  <div id="app">
    <el-container>
<!--      <div class="logo">-->
<!--        <router-link to="/home">-->
<!--          <div class="logo-content">-->
<!--            <img src="~@/../public/images/wit_logo.png" height="50px" alt="Wit Logo">-->
<!--          </div>-->
<!--        </router-link>-->
<!--      </div>-->
      <transition name="header"
                  appear
                  enter-active-class="animate__animated animate__slideInDown"
                  leave-active-class="animate__animated animate__slideOutUp">
        <wit-header v-show="!isHeaderHide"></wit-header>
      </transition>
      <wit-scroll class="main-scroll" ref="main_scroll" :probe-type="3" :on-scroll="onScroll">
        <keep-alive>
          <router-view @ContentActivated="ContentActivated"
                       @ContentUpdated="ContentUpdated"
                       @WitScrollTo="WitScrollTo"></router-view>
<!--          <router-view></router-view>-->
        </keep-alive>
<!--        <router-view @ContentMounted="ContentMounted"></router-view>-->
        <wit-footer></wit-footer>
      </wit-scroll>
<!--      <aboutus-nav @WitScrollTo="WitScrollTo"></aboutus-nav>-->
      <wit-image-viewer></wit-image-viewer>
    </el-container>
  </div>
</template>

<script>
  import WitHeader from "@/components/content/WitHeader/WitHeader";
  import WitScroll from "@/components/common/WitBaseUI/WitScroll/WitScroll";
  import WitFooter from "@/components/content/WitFooter/WitFooter";
  import WitImageViewer from "@/components/common/WitBaseUI/WitImageViewer/WitImageViewer";
  // import AboutusNav from "@/views/aboutus/AboutusNavigation/AboutusNav";

  export default {
    name: 'App',
    components: {
      WitHeader,
      WitScroll,
      WitFooter,
      WitImageViewer,
      // AboutusNav
    },
    data(){
      return{
        mainScrollYPos: 0,
        isScrollToUp: true
      }
    },
    methods:{
      ContentUpdated(){
        this.$refs.main_scroll.scroll_refresh()
      },
      ContentMounted(){
        this.$refs.main_scroll.scroll_refresh()
      },
      ContentActivated(){
        this.$refs.main_scroll.scroll_refresh()
        this.WitScrollTo(0, 0, 0, undefined)
      },
      onScroll(position){
        this.mainScrollYPos = position.y;
      },
      WitScrollTo(x, y, time, easing, extraTransform){
        let maxScrollY = this.$refs.main_scroll.scroll.maxScrollY
        if(y + maxScrollY > 0) y = -maxScrollY
        this.$refs.main_scroll.witScrollTo(x, 0-y, time, easing, extraTransform)
      }
    },
    computed:{
      isImgPreview(){
        return this.$store.getters.showImgViewer
      },
      isHeaderHide(){
        return (!this.isScrollToUp || this.isImgPreview)
      }
    },
    watch:{
      isImgPreview(){
        if(this.isImgPreview){
          this.$refs.main_scroll.enableScroll(false)
        }else{
          this.$refs.main_scroll.enableScroll()
        }
      },
      mainScrollYPos(newVal, oldVal){
        this.isScrollToUp = newVal > oldVal;
      }
    },
    mounted() {
      window.onresize = () => {
        return (() => {
          this.$store.commit('winWidthMut', document.body.clientWidth);
          this.$store.commit('winHeightMut', document.body.clientHeight);
        })()
      }
      this.$store.commit('winWidthMut', document.body.clientWidth);
      this.$store.commit('winHeightMut', document.body.clientHeight);
    }
  }
</script>

<style>
  @import "assets/css/base.css";
  @import "views/home/comps/HomeProduction/css/gallery.css";
  #app{
    overflow: hidden;
  }
  .main-scroll{
    height: 100vh;
    width: 100%;
  }
  .main-scroll.el-scrollbar .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .el-carousel--horizontal {
    height: 100%;
  }
  .el-avatar>img{
    margin: 0 auto;
  }
  .wit-timeline .history-timeline .el-timeline-item__timestamp{
    position: absolute;
    left: -60px;
    top: -10px;
    font-size: 20px;
    font-weight: bolder;
    color: #fff;
    line-height: 28px;
  }
  .wit-timeline .history-timeline .el-timeline-item__content{
    font-size: 16px;
    color: #fff;
    line-height: 22px;
  }

  /*.logo{*/
  /*  width: 200px;*/
  /*  position: fixed;*/
  /*  top: 5px;*/
  /*  left: 30px;*/
  /*}*/
  /*.logo .logo-content{*/
  /*  margin-top: 5px;*/
  /*}*/
  /*.logo .logo-content img{*/
  /*  margin-top: 5px;*/
  /*  height: 50px;*/
  /*}*/
</style>
