import Vue from 'vue'
import App from './App.vue'
import router from "@/router";
import store from './store'
// import { Button, Select, Row, Input } from 'element-ui';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/components/common/WitBaseUI/iconfont/iconfont.css'
import animate from 'animate.css'

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(animate)
// Vue.use(Button)
// Vue.use(Select)
// Vue.use(Row)
// Vue.use(Input)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
