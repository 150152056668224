<template v-if="preview">
  <image-viewer :z-index="zIndex" :initial-index="imageIndex" v-if="showViewer" :on-close="closeViewer" :url-list="previewSrcList"/>
</template>

<script>
  import ImageViewer from 'element-ui/packages/image/src/image-viewer'
  export default {
    name: "WitImageViewer",
    components:{
      ImageViewer
    },
    props: {
      zIndex: {
        type: Number,
        default: 2000
      }
    },
    watch:{
      showViewer(newVal, oldVal){
        this.$emit('showViewerChange', newVal)
      }
    },
    computed:{
      showViewer(){
        return this.$store.getters.showImgViewer
      },
      previewSrcList() {
        return this.$store.getters.previewSrcList
      },
      src(){
        return this.$store.getters.previewImgSrc
      },
      preview() {
        const  previewSrcList  = this.previewSrcList;
        return Array.isArray(previewSrcList) && previewSrcList.length > 0;
      },
      imageIndex() {
        let previewIndex = 0;
        const srcIndex = this.previewSrcList.indexOf(this.src);
        if (srcIndex >= 0) {
          previewIndex = srcIndex;
        }
        return previewIndex;
      }
    },
    methods:{
      closeViewer() {
        document.body.style.overflow = this.$store.getters.preOverflow;
        this.$store.commit('showImgViewerMut', false);
      }
    }
  }
</script>

<style scoped>

</style>
