<template>
  <div class="wit-block-content-container" :style="{'background-image': imgUrl}">
    <slot name="pre-append"></slot>
    <div class="mask" v-if="isMaskShow" :style="{'background-color': maskColor}"></div>
    <div class="container-wrapper">
      <div class="content" :class="contentClass" :style="contentStyle">
        <slot></slot>
      </div>
    </div>
    <slot name="post-append"></slot>
  </div>
</template>

<script>
  export default {
    name: "WitBlockContentContainer",
    props:{
      height: {
        type: String,
        default:()=>'100%'
      },
      contentClass:{
        type: String,
        default:()=>''
      },
      contentStyle:{
        type: Object,
        default:()=>{}
      },
      bgImgUrl:{
        type: String,
        default:()=>'none'
      },
      maskColor: {
        type: String,
        default:()=>''
      }
    },
    computed:{
      imgUrl(){
        if(this.bgImgUrl === 'none')
          return 'none'
        else{
          return 'url('+this.bgImgUrl+')'
        }
      },
      isMaskShow(){
        return this.maskColor !== ''
      }
    }
  }
</script>

<style scoped>
  .wit-block-content-container{
    position: relative;
    width: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .wit-block-content-container .container-wrapper{
    position: relative;
    max-width: var(--content-wrapper-width);
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
  .wit-block-content-container .container-wrapper .content{
    position: relative;
    width: 100%;
    height: 100%;
  }
  .wit-block-content-container .mask{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
</style>
