<template v-if="show">
  <div class="footer-info-block">
    <div class="title font-bold font-14 fc-gray-white">{{ title }}</div>
    <div class="item font-12" v-for="(item, index) in items" :key="index">
      <span v-if="isItemStr(item)">{{ item }}</span>
      <a v-else-if="isItemObj(item)" :href="item.link">
        {{ item.text }}
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    name: "FooterInfoBlock",
    props:{
      title: String,
      items: Array
    },
    computed:{
      show(){
        return this.title !== '' && this.items.length > 0
      }
    },
    methods:{
      isItemObj(item){
        return typeof item === typeof {}
      },
      isItemStr(item){
        return typeof item === typeof ''
      }
    }
  }
</script>

<style scoped>
  .footer-info-block{
    height: 100%;
    align-self: flex-start;
    margin: 40px 0;
  }
  .footer-info-block .title{
    margin: 20px 0 40px;
  }
  .footer-info-block .item{
    color: #BBBBBB;
    margin: 20px 0;
  }
  .footer-info-block .item a{
    color: #BBBBBB;
  }
  .footer-info-block .item a:hover{
    color: #e3e3e3;
    font-weight: bold;
  }
</style>
