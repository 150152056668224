<template>
  <div class="wrapper" ref="wrapper">
    <div class="content">
      <slot></slot>
    </div>
  </div>
<!--  <el-scrollbar>-->
<!--    <slot></slot>-->
<!--  </el-scrollbar>-->
</template>

<script>
  import BetterScroll from '@/components/common/WitBaseUI/WitScroll/js/better-scroll.js'
  export default {
    name: "WitScroll",
    props:{
      disableMouse:{
        type: Boolean,
        default(){
          return false
        }
      },
      probeType:{
        type: Number,
        default() {
          return 0;
        }
      },
      onScroll:{
        type: Function,
        default(){
          return null
        }
      }
    },
    data(){
      return{
        scroll: null
      }
    },
    methods:{
      scroll_refresh(){
        if(this.scroll != null){
          this.scroll.refresh()
        }
      },
      enableScroll(enable=true){
        if(this.scroll != null){
          if(enable){
            this.scroll.enable()
          }else{
            this.scroll.disable()
          }
        }
      },
      witScrollTo(x, y, time, easing, extraTransform){
        if(this.scroll != null){
          this.scroll.scrollTo(x, y, time, easing, extraTransform)
        }
      },
      debounce(func, delay){
        let timer = null;
        return function (...args){
          if(timer) clearTimeout(timer)
          timer = setTimeout(() => {
            func.apply(this, args)
          }, delay)
        }
      }
    },
    mounted() {
      this.scroll = new BetterScroll(this.$refs.wrapper, {
        scrollbar: true,
        mouseWheel: {
          speed: 20,
          invert: false,
          easeTime: 300
        },
        click: true,
        disableMouse: this.disableMouse,
        scrollX: false,
        bounce: false,
        probeType: this.probeType
      });
      const commit = this.debounce(this.$store.commit, 200)
      this.scroll.on('scroll', (position)=>{
        commit('currentScrollTopMut', -position.y)
        // this.$store.commit('currentScrollTopMut', -position.y)
        if(this.onScroll != null){
          this.onScroll(position)
        }
      });
      // this.scroll.scroller.hooks.on('resize', ()=>{
      //   console.log('resize');
      // });
    },
    watch:{
      $route(to, from){
        this.scroll_refresh()
      }
    }
  }
</script>

<style scoped>

</style>
