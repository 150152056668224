import Vue from "vue"
import VueRouter from 'vue-router'

const Home = () => import('../views/home/Home')
const AboutUs = () => import('../views/aboutus/AboutUs')
const Case = () => import('../views/case/Case')
const Information = () => import('../views/information/Information')
// const Contact = () => import('../views/contact/Contact')
const Services = () => import('../views/services/Services')
const Plantation = () => import('../views/plantation/Plantation')
const JoinUs = () => import('../views/joinus/JoinUs')
const Page404 = () => import('../views/error/Page404')
const Page500 = () => import('../views/error/Page500')
// import Home from "../views/home/Home";
// import AboutUs from "../views/aboutus/AboutUs";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name:'Home',
    meta:{
      title: '沃丁科技-首页'
    },
    component: Home,
  },
  {
    path: '/aboutus',
    name: 'AboutUs',
    component: AboutUs,
    meta:{
      title: '沃丁科技-关于我们'
    }
  },
  // {
  //   path: '/case',
  //   name: 'Case',
  //   component: Case,
  //   meta:{
  //     title: '沃丁科技-服务案例'
  //   }
  // },
  // {
  //   path: '/information',
  //   name: 'Information',
  //   component: Information,
  //   meta:{
  //     title: '沃丁科技-资讯'
  //   }
  // },
  {
    path: '/services',
    name: 'Services',
    component: Services,
    meta:{
      title: '沃丁科技-产品服务'
    }
  },
  {
    path: '/plantation',
    name: 'Plantation',
    component: Plantation,
    meta:{
      title: '沃丁科技-AI种植'
    }
  },
  {
    path: '/joinus',
    name: 'JoinUs',
    component: JoinUs,
    meta:{
      title: '沃丁科技-加入我们'
    }
  },
  {
    path: '/404',
    name: 'Page404',
    component: Page404,
    meta:{
      title: '404'
    }
  },
  {
    path: '/500',
    name: 'Page500',
    component: Page500,
    meta:{
      title: '500'
    }
  },
  {
    path: "*",
    redirect: "/404"
  }
]

const router = new VueRouter({
  routes,
  mode: "history"
})

//导航守卫
router.beforeEach((to, from, next) => {
  document.title = to.matched[0].meta.title;
  next()
})
// router.afterEach((to, from) => {
//   console.log(to);
// })
export default router
