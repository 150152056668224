<template>
  <router-link v-if="!isOutLink" :to="to" v-on:click.native="menuClick">
    <li class="header-mini-nav-item w-l-row-center-compact fc-d1"
        @mouseenter="isHovered=true"
        @mouseleave="isHovered=false">
      <div class="title font-14">
        <slot></slot>
      </div>
      <div class="indicator">
        <i class="iconfont icon-dot" :class="{'hover': isHovered, 'active': isActive}"></i>
      </div>
    </li>
  </router-link>
  <a v-else :href="to" target="_blank">
    <li class="header-mini-nav-item w-l-row-center-compact fc-d1"
        @mouseenter="isHovered=true"
        @mouseleave="isHovered=false">
      <div class="title font-14">
        <slot></slot>
      </div>
      <div class="indicator">
        <i class="iconfont icon-dot" :class="{'hover': isHovered, 'active': isActive}"></i>
      </div>
    </li>
  </a>
</template>

<script>
  export default {
    name: "HeaderMiniNavItem",
    props:{
      to:{
        type: String,
        require: true
      }
    },
    data(){
      return{
        isHovered: false
      }
    },
    computed:{
      isActive(){
        if (!this.isOutLink) {
          return this.$route.path === this.to
        }
      },
      isOutLink(){
        return this.to.startsWith("http");
      }
    },
    methods:{
      menuClick(){
        if (!this.isOutLink) {
          this.$emit("click")
        }
      }
    }
  }
</script>

<style scoped>
.header-mini-nav-item{
  margin: 0 15px;
  height: 100%;
}
.header-mini-nav-item .title{
  margin: 20px 10px;
}
li.header-mini-nav-item .indicator{
  height: 18px;
}
li.header-mini-nav-item .indicator i{
  font-size: 10px;
  transition: 0.7s all;
  opacity: 0;
}
li.header-mini-nav-item .indicator i.hover{
  opacity: 1;
}
li.header-mini-nav-item .indicator i.active{
  opacity: 1;
}
li.header-mini-nav-item:hover{
  color: #000;
  cursor: pointer;
}
</style>
