<template>
  <div class="header-nav-wrapper">
    <div class="logo">
      <router-link to="/home">
        <div class="logo-content">
          <slot name="logo"></slot>
        </div>
      </router-link>
    </div>
    <div class="header-nav">
      <ul class="nav-ul w-l-row-center-compact">
        <slot name="nav"></slot>
      </ul>
    </div>
    <div class="menu w-l-row-center-compact" v-on:click="miniMenuClick">
        <i class="iconfont icon-menu"></i>
    </div>
    <div class="mini-menu-block w-l-column-center-compact" v-if="isExpended">
      <slot name="mini-nav"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "HeaderNavWrapper",
    data(){
      return{
        publicPath: process.env.BASE_URL,
        isExpended: false
      }
    },
    methods:{
      miniMenuClick(){
        this.isExpended = !this.isExpended
      },
      setMiniMenuHidden(){
        this.isExpended = false
      }
    }
  }
</script>

<style scoped>
  .header-nav-wrapper{
    height: 100%;
    max-width: var(--content-wrapper-width);
    width: 100%;
    margin: 0 auto;
    background-color: transparent;
  }
  .header-nav-wrapper .mini-menu-block{
    position: fixed;
    display: none;
    top: 60px;
    right: 0;
    height: calc(100vh - 60px);
    width: 100vw;
    background-color: rgba(255,255,255, 0.8);
  }

  .header-nav-wrapper .header-nav{
    position: relative;
    float: right;
    height: 100%;
    min-width: 100px;
  }

  .header-nav-wrapper .logo{
    width: 200px;
    float: left;
  }
  .header-nav-wrapper .logo .logo-content{
    margin-top: 5px;
  }
  .header-nav-wrapper .logo .logo-content img{
    margin-top: 5px;
    height: 50px;
  }
  .header-nav-wrapper .header-nav .nav-ul{
    height: 100%;
    list-style: none;
  }
  .header-nav-wrapper .menu{
    display: none;
    float: right;
    height: 100%;
    width: 60px;
  }
  .header-nav-wrapper .menu i{
    color: black;
    font-size: 20px;
  }
  .header-nav-wrapper .menu i:hover{
    cursor: pointer;
    color: white;
  }
  /*pc*/
  @media screen and (min-width: 960px){
    /*.header-nav-item{*/
    /*  background-color: #3a32a5;*/
    /*}*/
  }
  @media screen and (min-width: 960px) and (max-width: 1200px){
    /*.wit-header{*/
    /*  width: 100%;*/
    /*}*/
  }
  /*phone*/
  @media screen and (max-width: 960px){
    /*.wit-header{*/
    /*  width: 100%;*/
    /*}*/
    .header-nav-wrapper .header-nav{
      display: none;
    }
    .header-nav-wrapper .menu{
      display: flex;
    }
    .header-nav-wrapper .mini-menu-block{
      display: flex;
    }
  }
</style>
