<template>
  <wit-block-content-container class="wit-footer"
                               content-class="w-l-row-center-compact w-l-jc-between fc-gray-white">
    <div class="symbol w-l-column-rs-compact">
      <div class="icon">
        <img src="~@/../public/images/wit_logo_r.png" height="50px" alt="Wit Logo">
      </div>
      <div class="subject font-12">
        沃丁科技致力于改变未来
      </div>
    </div>
    <span class="divider-column"></span>
    <footer-info-block class="contact" :title="contacts.title" :items="contacts.items"></footer-info-block>
    <span class="divider-column"></span>
    <footer-info-block class="links" :title="links.title" :items="links.items"></footer-info-block>
    <span class="divider-column"></span>
    <footer-info-block class="join-us" :title="joinUs.title" :items="joinUs.items"></footer-info-block>
    <template v-slot:post-append>
      <el-footer class="copyright w-l-row-center-compact font-12 fc-white">
        <span style="margin-right: 10px;">版权所有 © 沃丁科技（上海）有限公司 2020-{{ new Date().getFullYear() }}</span>
        <span><a href="https://beian.miit.gov.cn/" target="_blank" class="font-12 fc-white">沪ICP备20011456号-1</a></span>
      </el-footer>
    </template>
  </wit-block-content-container>
</template>

<script>
  import WitBlockContentContainer
    from "@/components/common/WitBaseUI/WitBlockContentContainer/WitBlockContentContainer";
  import FooterInfoBlock from "@/components/content/WitFooter/FooterInfoBlock";
  export default {
    name: "WitFooter",
    components:{
      WitBlockContentContainer,
      FooterInfoBlock
    },
    data(){
      return{
        contacts:{
          title: '联系我们',
          items:[
            '商务合作：panmengjiao@worldintek.com',
            '售前资讯：021-67103528',
            '投资合作：zhaoyiyang@worldintek.com',
            '媒体合作：panmengjiao@worldintek.com'
          ]
        },
        links:{
          title: '相关连接',
          items:[
            {
              text: '人工智能将吞噬数据',
              link: 'javascript:void(0)'
            },
            {
              text: '如何构建全场景智慧生活？',
              link: 'javascript:void(0)'
            },
            {
              text: '供应链为什么要引入区块链？',
              link: 'javascript:void(0)'
            },
            {
              text: '下一站AI：实时服务',
              link: 'javascript:void(0)'
            }
          ]
        },
        joinUs:{
          title: '加入我们',
          items:[
            {
              text: '热招岗位',
              link: 'javascript:void(0)'
            },
            {
              text: '实习生',
              link: 'javascript:void(0)'
            },
            {
              text: 'AI团队',
              link: 'javascript:void(0)'
            }
          ]
        }
      }
    }
  }
</script>

<style scoped>
  .wit-footer{
    width: 100%;
    background-color: #262626;
  }
  .wit-footer .copyright{
    background-color: black;
  }
  .wit-footer .divider-column{
    display: block;
    margin: 0;
    padding: 0;
    width: 1px;
    height: 180px;
    font-size: 0;
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: #444444;
  }

  .wit-footer .symbol>div{
    margin: 10px 0;
  }
  .wit-footer .symbol .subject{
    letter-spacing: 2px;
    margin-left: 8px;
  }
</style>
