<template>
  <el-header class="wit-header-container">
    <header-nav-wrapper ref="header_nav">
      <template v-slot:logo>
        <img src="~@/../public/images/wit_logo.png" height="50px" alt="Wit Logo">
      </template>
      <template v-slot:nav>
        <header-nav-item to="https://ticketing-h5.worldintek.com">票务系统</header-nav-item>
        <header-nav-item to="/home">首页</header-nav-item>
        <header-nav-item to="/aboutus">关于我们</header-nav-item>
        <header-nav-item to="/services">租赁种植</header-nav-item>
        <header-nav-item to="/plantation">AI种植</header-nav-item>
        <header-nav-item to="/joinus">加入沃丁</header-nav-item>
      </template>
      <template v-slot:mini-nav>
        <header-mini-nav-item to="https://ticketing-h5.worldintek.com" @click="miniMenuClick">票务系统</header-mini-nav-item>
        <header-mini-nav-item to="/home" @click="miniMenuClick">首页</header-mini-nav-item>
        <header-mini-nav-item to="/aboutus" @click="miniMenuClick">关于我们</header-mini-nav-item>
        <header-mini-nav-item to="/services" @click="miniMenuClick">租赁种植</header-mini-nav-item>
        <header-mini-nav-item to="/plantation" @click="miniMenuClick">AI种植</header-mini-nav-item>
        <header-mini-nav-item to="/joinus" @click="miniMenuClick">加入沃丁</header-mini-nav-item>
      </template>
    </header-nav-wrapper>
  </el-header>
</template>

<script>
  import HeaderNavWrapper from "@/components/content/WitHeader/HeaderNavWrapper";
  import HeaderNavItem from "@/components/content/WitHeader/HeaderNavItem";
  import HeaderMiniNavItem from "@/components/content/WitHeader/HeaderMiniNavItem";
  export default {
    name: "WitHeader",
    components:{
      HeaderNavWrapper,
      HeaderNavItem,
      HeaderMiniNavItem
    },
    methods:{
      miniMenuClick() {
        this.$refs.header_nav.setMiniMenuHidden()
      }
    }
  }
</script>

<style scoped>
  .wit-header-container{
    position: fixed;
    width: 100%;
    z-index: 10000;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0.5);
    /*opacity: 0.2;*/
  }
  /*.wit-header-container:before{*/
  /*  content: "";*/
  /*  display: block;*/
  /*  position: absolute;*/
  /*  left: 0;*/
  /*  top: 0;*/
  /*  right: 0;*/
  /*  height: 100px;*/
  /*  background: url('/images/head.png') center repeat-x;*/
  /*  background-size: auto 100%;*/
  /*}*/
</style>
