import Vue from 'vue'
import Vuex from  'vuex'

Vue.use(Vuex)
const moduleA = {
  state:{
    name: 'test'
  },
  mutations:{},
  actions:{},
  getters:{}
}

const store = new Vuex.Store({
  state: {
    winHeight: 937,
    winWidth: 1920,
    showImgViewer: false,
    preOverflow: '',
    previewImgSrc: '',
    currentScrollTop: 0,
    previewSrcList: []
  },
  mutations:{
    winHeightMut(state,height){
      state.winHeight = height
    },
    winWidthMut(state, width){
      state.winWidth = width
    },
    showImgViewerMut(state, showImgViewer){
      state.showImgViewer = showImgViewer
    },
    previewImgSrcMut(state, previewImgSrc){
      state.previewImgSrc = previewImgSrc
    },
    previewSrcListMut(state, previewSrcList){
      state.previewSrcList = previewSrcList
    },
    preOverflowMut(state, preOverflow){
      state.preOverflow = preOverflow
    },
    currentScrollTopMut(state, top){
      state.currentScrollTop = top
    }
  },
  actions:{
    // aUpdate(context){
    //     setTimeout(() => {
    //         context.commit('increment')
    //     }, 1000)
    // }
    aUpdate(context, payload){
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          context.commit('increment')
          console.log(payload);
          resolve('data----');
        }, 1000)
      })
    }
  },
  getters:{
    powerCounter(state /*,getters ,rootState(模块中使用)*/){
      return state.counter * state.counter;
      //可返回函数 args在调用时传入
      // return function (args) {
      //     return state.counter * args;
      // }
    },
    winWidth(state){
      return state.winWidth
    },
    winHeight(state){
      return state.winHeight
    },
    showImgViewer(state){
      return state.showImgViewer
    },
    previewImgSrc(state){
      return state.previewImgSrc
    },
    previewSrcList(state){
      return state.previewSrcList
    },
    preOverflow(state){
      return state.preOverflow
    },
    currentScrollTop(state){
      return state.currentScrollTop
    }
  },
  modules:{
    a:moduleA
  }
})
export default store
