<template>
  <router-link v-if="!isOutLink" :to="to">
    <li class="header-nav-item w-l-column-center-compact fc-d1"
        @mouseenter="isHovered=true"
        @mouseleave="isHovered=false">
      <div class="title font-14">
        <slot></slot>
      </div>
      <div class="indicator">
        <i class="iconfont icon-dot" :class="{'hover': isHovered, 'active': isActive}"></i>
      </div>
    </li>
  </router-link>
  <a v-else :href="to" target="_blank">
    <li class="header-nav-item w-l-column-center-compact fc-d1"
        @mouseenter="isHovered=true"
        @mouseleave="isHovered=false">
      <div class="title font-14">
        <slot></slot>
      </div>
      <div class="indicator">
        <i class="iconfont icon-dot" :class="{'hover': isHovered, 'active': isActive}"></i>
      </div>
    </li>
  </a>
</template>

<script>
  export default {
    name: "HeaderNavItem",
    props:{
      to:{
        type: String,
        require: true
      },
    },
    data(){
      return{
        isHovered: false
      }
    },
    computed:{
      isActive(){
        if (!this.isOutLink) {
          return this.$route.path === this.to
        }
      },
      isOutLink(){
        return this.to.startsWith("http");
      }
    }
  }
</script>

<style scoped>
  .header-nav-item{
    margin: 0 15px;
    width: 70px;
    height: 100%;
  }
  .header-nav-item .title{
    padding-top: 20px;
  }
  li.header-nav-item .indicator{
    height: 18px;
  }
  li.header-nav-item .indicator i{
    font-size: 10px;
    transition: 0.7s all;
    opacity: 0;
  }
  li.header-nav-item .indicator i.hover{
    opacity: 1;
  }
  li.header-nav-item .indicator i.active{
    opacity: 1;
  }
  li.header-nav-item:hover{
    color: #000;
    cursor: pointer;
  }
</style>
