import { render, staticRenderFns } from "./WitHeader.vue?vue&type=template&id=c6ee5146&scoped=true&"
import script from "./WitHeader.vue?vue&type=script&lang=js&"
export * from "./WitHeader.vue?vue&type=script&lang=js&"
import style0 from "./WitHeader.vue?vue&type=style&index=0&id=c6ee5146&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6ee5146",
  null
  
)

export default component.exports